.modalBackground {
  position: fixed;
  bottom: 0; /* Position at the bottom */
  right: 0; /* Position at the right */
  display: flex;
  justify-content: flex-end; /* Align content to the right */
  align-items: flex-end; /* Align content to the bottom */
  width: 100%;
  height: 100%;
}

.modalContainer {
  width: 500px;
  max-height: 500px; /* Set a maximum height for the container */
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px;
  overflow-y: auto; /* Add vertical scrollbar when content overflows */
  /* Add additional styles as needed for your modal content */
}
.modalContainer {
  display: inline-block;
  text-align: center;
  margin-top: 1px;
}

.titleCloseBtn {
  display: flex;
  justify-content: space-between;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  width: 40px; /* Adjust the width and height to create a circular button */
  height: 40px;
  border-radius: 50%; /* Make the button circular */
  transition: background-color 0.3s, transform 0.3s; /* Add transition properties */
}

.titleCloseBtn button:hover {
  background-color: lightgray; /* Change to your desired hover color */
  transform: scale(1.1); /* Add a scale effect on hover to make it slightly larger */
}
