* {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  
}

table {
  width: 100%;
  table-layout: fixed;
  background-color: #f2f2f2;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background: rgba(216, 73, 73, 0.2); */
  width: 100%;
  font-weight: 500;
  font-size: 15px;
}

.pagination-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #222;
  /* padding: 8px 14px; */
  height: 40px;
  width: 40px;
  text-decoration: none;
  transition: background-color 0.2s;
  border: 1px solid #ddd;
  cursor: pointer;
}

.pagination-container button.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #7cbddb;
}

.pagination-container button.disabled {
  opacity: 0.2;
}
.pagination-container button:hover:not(.active) {
  background-color: #007bff;
}

/* CSS file */
.main-screen {
  height: 100vh; /* Set the height of the main screen to full viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.model-component {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f9f9f9;
  padding: 20px;
}

@media (min-width: 1040px) {
  body {
    max-width: 100%; /* Set your desired maximum width */
    margin: 0 auto; /* Center the content within the maximum width */
  }
}
