.box {
    box-shadow: #222
  }
.box:hover {
    transform: translateY(5py);
    box-shadow: 0px 10px 20px 2px rgba(200, 32, 32, 0.25);
  }

/* Generate classes for widths from 1% to 20% */
.width-1p {
  width: 1%;
}

.width-2p {
  width: 2%;
}

.width-3p {
  width: 3%;
}

.width-4p {
  width: 4%;
}

.width-5p {
  width: 5%;
}

.width-6p {
  width: 6%;
}

.width-7p {
  width: 7%;
}

.width-8p {
  width: 8%;
}

.width-9p {
  width: 9%;
}

.width-10p {
  width: 10%;
}

.width-11p {
  width: 11%;
}

.width-12p {
  width: 12%;
}

.width-13p {
  width: 13%;
}

.width-14p {
  width: 14%;
}

.width-15p {
  width: 15%;
}

.width-16p {
  width: 16%;
}

.width-17p {
  width: 17%;
}

.width-18p {
  width: 18%;
}

.width-19p {
  width: 19%;
}

.width-20p {
  width: 20%;
}


.b_w {
  overflow-wrap: break-word;
}
.sticky-header {
  position: sticky;
  top: 0;
  background-color: #f5f5f5; /* Add a background color to distinguish the header */
  height: 50px; /* Set the desired height for the header */
}

tr:hover {
    background-color: #c1d3f0;
    color: #222

}

.bg-alert-success {

background-color: #6CB291;
}

.refresh-icon {
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.refresh-icon:hover {
  color: red; /* Change the color to red on hover, you can choose any color you like */
}

#alertMessage {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ff0000; /* Adjust this to your desired background color */
  color: #ffffff; /* Adjust this to your desired text color */
  text-align: center;
  padding: 10px;
}

.hidden {
  display: none;
}

.wrap-text {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
}