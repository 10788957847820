.box {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  }
.box:hover {
    transform: translateY(5py);
    box-shadow: 0px 10px 20px 2px rgba(200, 32, 32, 0.25);
  }

.width-1p 
{
width: 1%;
}

.width-2p 
{
width: 2%;
}

.width-3p 
{
width: 3%;
}

.width-4p 
{
width: 4%;
}

.width-5p 
{
width: 5%;
}

.width-6p 
{
width: 6%;
}

.width-7p 
{
width: 7%;
}

.width-8p 
{
width: 8%;
}

.width-9p 
{
width: 9%;
}

.b_w {
  overflow-wrap: break-word;
}
.sticky-header {
  position: sticky;
  top: 0;
  background-color: #f5f5f5; /* Add a background color to distinguish the header */
  height: 50px; /* Set the desired height for the header */
}

